<template>
  <a
    href="#"
    class="btn btn-secondary-solid d-lg-none mr-4"
    @click="$store.commit('toggleSidebar')"
    v-if="cmeOnly == undefined || !cmeOnly"
  >
    <aad-icon
      name="close"
      v-if="$store.state.mobileSidebarOpen"
      class="sidebar-icon"
      id="close-icon"
    />

    <aad-icon
      name="hamburger"
      v-else
      class="sidebar-icon"
      id="hamburger-icon"
    />
  </a>
</template>
<script>
import {  mapState } from "vuex";


export default {
  computed: {
    ...mapState(["cmeOnly"]),
  },
};
</script>


<style scoped>
.sidebar-icon {
  color: #fff;
  height: 24px;
  width: 24px;
  padding: 2px;
  margin: 0px;
}
</style>
