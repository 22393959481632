var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card",class:{
    'card-lg': _vm.type === 'default-lg',
    'default-lg': _vm.type === 'default-lg',
  }},[(_vm.routeLink != undefined)?_c('router-link',{attrs:{"to":_vm.routeLink}},[_c('img',{staticClass:"card-img-top",attrs:{"src":_vm.imageUrl,"alt":"Card image"}})]):(_vm.href != undefined)?_c('a',{attrs:{"href":_vm.href,"target":"_blank"}},[_c('img',{staticClass:"card-img-top",attrs:{"src":_vm.imageUrl,"alt":"Card image"}})]):_c('div',[_c('img',{staticClass:"card-img-top",attrs:{"src":_vm.imageUrl,"alt":"Card image"}})]),_c('div',{staticClass:"card-body"},[(_vm.routeLink != undefined)?_c('router-link',{staticClass:"card-title",class:{ 'card-title-lg': _vm.type === 'default-lg' },attrs:{"to":_vm.routeLink}},[_vm._v(" "+_vm._s(_vm.title)+" ")]):(_vm.href != undefined)?_c('a',{staticClass:"card-title",class:{
        'card-title-lg': _vm.type === 'default-lg',
      },attrs:{"href":_vm.href,"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.title)+" ")]):_c('p',{staticClass:"card-title",class:{
        'card-title-lg': _vm.type === 'default-lg',
      }},[_vm._v(" "+_vm._s(_vm.title)+" ")]),(_vm.description != '')?_c('p',{staticClass:"card-text"},[_vm._v(" "+_vm._s(_vm.description)+" ")]):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }