import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import AadIcon from "./components/AadIcon.vue";
import AadTitle from "./components/AadTitle.vue";
import AadLoader from "./components/AadLoader.vue";
import AadBack from "./components/AadBack.vue";
import Meta from "vue-meta";
// This import loads the firebase namespace.
import firebase from "firebase/app";
// These imports load individual services into the firebase namespace.
import "firebase/auth";
import "firebase/database";
import "firebase/firestore";
import "firebase/analytics";

Vue.config.productionTip = false;
Vue.use(Meta);

// ------- Setup Firebase -----------------------------
firebase.initializeApp({
  apiKey: process.env.VUE_APP_API_KEY,
  authDomain: process.env.VUE_APP_AUTH_DOMAIN,
  databaseURL: process.env.VUE_APP_DATABASE_URL,
  projectId: process.env.VUE_APP_PROJECT_ID,
  storageBucket: process.env.VUE_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_APP_ID,
  measurementId: process.env.VUE_APP_MEASUREMENT_ID,
});

// -------------- Firebase Analytics logging -----------------------
const analytics = firebase.analytics();
Vue.config.productionTip = false;
// alias
Vue.prototype.$analytics = analytics;

// ----------------- Firestore db ---------------------------------
export const db = firebase.firestore();

// ------------------------- Register global components ----------------------------------
Vue.component("aad-icon", AadIcon);
Vue.component("aad-title", AadTitle);
Vue.component("aad-loader", AadLoader);
Vue.component("aad-back", AadBack);

// ------------ Setup firebase Authentication based on stored user. If there is a user, they will log in after app creation ----------------------------
let storedUser = localStorage.getItem("user")
  ? JSON.parse(localStorage.getItem("user"))
  : undefined;

// If there is no user in storage, connect to firebase anonymously
if (!storedUser) {
  firebase
    .auth()
    .signInAnonymously()
    .then(() => {
      launchApplication();
    });
}

// ------------ Firebase Authentication before app created for stored users ----------------------------

// If there is a user in storage, connect to firebase
if (storedUser) {
  store
    .dispatch("login", {
      regCode: storedUser.registrationCode,
      lastName: storedUser.lastName,
    })
    .then(() => {
      launchApplication();
    });
}

// Needs to be called after authentication.
function launchApplication() {
  // ----------------------- Load the meetings before app start ------------------------------
  // NOTE: Needs to be authenticated first
  store.dispatch("loadMeetings");

  // -------------------- Instantiate Vue app ----------------------------------------------
  new Vue({
    router,
    store,
    created() {},
    methods: {},
    computed: {},
    render: (h) => h(App),
  }).$mount("#app");
}
