import Vue from "vue";
import Router from "vue-router";
import Home from "../views/Home.vue";
import firebase from "firebase/app";
import "firebase/analytics";
import store from "@/store";
import baseConfig from "@/store/baseConfig.js";

Vue.use(Router);

let cmeOnly = baseConfig.CmeOnly || false;

let routes = [];

// If this is a normal meeting app, set the standard route list
if(!cmeOnly){
  routes = [
    /* Hard Coded */
    {
      path: "/",
      name: "Home",
      component: Home,
    },
    {
      path: "/login",
      name: "Login",
      component: () =>
        import(/* webpackChunkName: "Login" */ "../views/Login.vue"),
    },
    {
      path: "/notes",
      name: "Notes",
      component: () =>
        import(/* webpackChunkName: "Notes" */ "../views/Notes.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/secretPage",
      name: "SecretPage",
      component: () =>
        import(/* webpackChunkName: "SecretPage" */ "../views/SecretPage.vue"),
      meta: {
        requiresAuth: true,
      },
    },

    /* Sections from Firestore */
    {
      path: "/ask-me",
      name: "AskMe",
      component: () =>
        import(/* webpackChunkName: "AskMe" */ "../views/AskMe.vue"),
    },
    {
      path: "/maps",
      name: "Maps",
      component: () => import(/* webpackChunkName: "Maps" */ "../views/Maps.vue"),
    },
    {
      path: "/cctf",
      name: "CCTF",
      component: () => import(/* webpackChunkName: "CCTF" */ "../views/CCTF.vue"),
    },
    {
      path: "/cme",
      name: "CME",
      component: () => import(/* webpackChunkName: "CME" */ "../views/CME.vue"),
      meta: {
        requiresAuth: true,
        hideSponsorInfo: true,
      },
    },
    {
      path: "/cme/:id",
      name: "CMESurvey",
      component: () =>
        import(/* webpackChunkName: "CMESurvey" */ "../views/CMESurvey.vue"),
      meta: {
        requiresAuth: true,
        hideSponsorInfo: true,
      },
    },
    {
      path: "/handouts",
      name: "Handouts",
      component: () =>
        import(/* webpackChunkName: "Handouts" */ "../views/Handouts.vue"),
      meta: {
        requiresAuth: true,
        pressPassCodeAllowed: true 
      },
    },
    {
      path: "/maps/:id",
      name: "MapsId",
      component: () => import(/* webpackChunkName: "Maps" */ "../views/Maps.vue"),
    },
    {
      path: "/sessions",
      name: "Sessions",
      component: () =>
        import(/* webpackChunkName: "Sessions" */ "../views/Sessions.vue"),
      meta: {
        hideSponsorInfo: true,
      },
    },
    {
      path: "/sessions/:id",
      name: "SessionDetails",
      component: () =>
        import(
          /* webpackChunkName: "SessionDetails" */ "../views/SessionDetails.vue"
        ),
      meta: {
        hideSponsorInfo: true,
      },
    },
    {
      path: "/speakers",
      name: "Speakers",
      component: () =>
        import(/* webpackChunkName: "Speakers" */ "../views/Speakers.vue"),
      meta: {
        hideSponsorInfo: true,
      },
    },
    {
      path: "/speakers/:id",
      name: "SpeakerDetails",
      component: () =>
        import(
          /* webpackChunkName: "SpeakerDetails" */ "../views/SpeakerDetails.vue"
        ),
      meta: {
        hideSponsorInfo: true,
      },
    },

    /* Default/404 */
    {
      path: "/404",
      alias: "/:pathMatch(.*)*",
      name: "NotFound",
      component: () =>
        import(
          /*webpackChunkName: "NotFound" */
          "../views/NotFound.vue"
        ),
    },
  ];
} 
// If this is a CME only application (VMX), then set up these routes
else {
  routes = [
    /* Hard Coded */
    {
      path: "/",
      name: "CME",
      component: () => import(/* webpackChunkName: "CME" */ "../views/CME.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/login",
      name: "Login",
      component: () =>
        import(/* webpackChunkName: "Login" */ "../views/Login.vue"),
    },
    {
      path: "/cme",
      redirect: {name: 'CME'}
    },
    {
      path: "/cme/:id",
      name: "CMESurvey",
      component: () =>
        import(/* webpackChunkName: "CMESurvey" */ "../views/CMESurvey.vue"),
      meta: {
        requiresAuth: true,
      },
    },
  ]
}

let router = new Router({
  routes: routes,
  mode: "history",
});

// Route guards
router.beforeEach((to, from, next) => {
  let isAuthenticated = store.getters.isLoggedIn || false;

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    // Allow press to access routes with pass code
    if(to.meta && to.meta.pressPassCodeAllowed && to.query.pressPassCode == "xYJbZ0DmKaSNt0gnnaAL"){
      next();
      return;
    }

    // needs to login
    if (!isAuthenticated) {
      next({ name: "Login", query: { redirect: to.fullPath, name: to.name } });
    } else {
      next();
    }
  } else {
    next();
  }
});

router.afterEach(() => {
  // log internal page switching
  firebase.analytics().setCurrentScreen(window.location.pathname);
  firebase.analytics().logEvent("screen_view");

  // Close sidebar no matter what on new page in mobile
  store.state.mobileSidebarOpen = false;
});

export default router;
