const baseConfig = {
  MeetingCode: "VMX2022",
  MeetingName: "2022 VMX",
  CmePdfLink: "https://assets.ctfassets.net/b81p2f4p3o1v/3jVtdrdBtAgVPJQjFLqQ6G/426ddf077dbdad9fd18692c57f71fd20/AM22_CME_Information.pdf",
  CmeOnly: true,
  LoginUsingRegCode: false,
  IsCmeSingleDay: true,
  Sponsor: {
    IsActive: true,
    Url: "https://www.bms.com/",
    // Need to use require on image urls for webpack to pick up in compile time
    ImageUrl: require("@/assets/sponsorAM2022.png"),
    ImageAltText: "Sponsor Image ",
    BoothNumber: '',
  },
  Testers: [
    604687, 604688, 604690, 604692, 604693, 604694, 604695, 604696, 604697,
    604698, 604699, 604700, 604701, 604751, 604752, 604753, 604755, 604756,
    604757, 604758, 604759, 604760, 604761, 604762, 604763, 604764, 604765,
    604767, 604768, 604783, 604790, 604791, 604792, 604793, 606707, 606708,
    606725, 606727, 606728, 606746, 606747, 606748, 606749, 606750, 606751,
    606772, 606773, 606774, 606775, 606776, 606778, 606780, 606781, 606783,
    606784, 606785, 606786, 606787, 606788, 606789, 606790, 606791, 606792,
    606793, 606794, 606795, 606796, 606797, 606798, 606799, 606800, 606801,
    606802, 606803, 606804, 606805, 606806, 606808, 606811, 606813, 606816,
    606817, 606818, 606819, 606822, 606823, 606824, 606825, 606826, 606827,
    606828, 606829, 606830, 606894, 606895, 606898, 606900, 606904, 606906,
    606907, 606908, 606912, 606913, 606915, 606970, 606971, 607046, 607047,
    607048, 607049, 607050, 607051, 607052, 607054, 607055, 607057, 607059,
    607062, 607076, 607078, 607079, 607080, 607081, 607082, 607100, 607130,
    607134, 607135, 607136, 607138, 607139, 607141, 607279, 608303, 612048,
  ], // Reg codes for the users testing, shows test sessions;
};

export default baseConfig;
