<template>
  <div class="home">
    <div v-if="sections && sections.length > 2">
      <aad-title>Explore</aad-title>
      <aad-card-deck>
        <aad-card
          v-for="(section, index) in sections"
          :key="index"
          class="section-card"
          :title="section.Name"
          :imageUrl="section.ImageURL"
          :routeLink="
            section.SectionType == 'Section'
              ? { name: section.LinkedPageName || 'NotFound' }
              : undefined
          "
          :href="
            section.SectionType == 'Url' ? section.DestinationURL : undefined
          "
        />
      </aad-card-deck>
    </div>
    <aad-loader v-else></aad-loader>
  </div>
</template>

<script>
import AadCardDeck from "@/components/AadCardDeck.vue";
import AadCard from "@/components/AadCard.vue";
import AadLoader from "@/components/AadLoader.vue";

export default {
  name: "Home",
  metaInfo: {
    title: "Explore",
  },
  components: { AadCardDeck, AadCard, AadLoader },
  computed: {
    sections() {
      return this.$store.getters.homePageSections;
    },
  },
  mounted() {
    if(this.sections == undefined || this.sections.length == 0){
      this.getSections();
    }
  },
  methods: {
    getSections() {
      this.$store.dispatch("loadSections");
    },
  },
};
</script>

<style>
/* The images come back in all shapes and sizes. I don't like this solution but we need something to make them consistent */
.section-card img {
  object-fit: cover;
  height: 200px;
}
</style>
