<template>
  <div
    class="card"
    :class="{
      'card-lg': type === 'default-lg',
      'default-lg': type === 'default-lg',
    }"
  >
    <!-- Image with or without various types of links -->
    <router-link v-if="routeLink != undefined" :to="routeLink">
      <img class="card-img-top" :src="imageUrl" alt="Card image" />
    </router-link>
    <a :href="href" v-else-if="href != undefined" target="_blank">
      <img class="card-img-top" :src="imageUrl" alt="Card image" />
    </a>
    <div v-else>
      <img class="card-img-top" :src="imageUrl" alt="Card image" />
    </div>

    <!-- Card Body -->
    <div class="card-body">
      <router-link
        v-if="routeLink != undefined"
        :to="routeLink"
        class="card-title"
        :class="{ 'card-title-lg': type === 'default-lg' }"
      >
        {{ title }}
      </router-link>
      <a
        v-else-if="href != undefined"
        :href="href"
        class="card-title"
        :class="{
          'card-title-lg': type === 'default-lg',
        }"
        target="_blank"
      >
        {{ title }}
      </a>
      <p
        v-else
        class="card-title"
        :class="{
          'card-title-lg': type === 'default-lg',
        }"
      >
        {{ title }}
      </p>

      <p v-if="description != ''" class="card-text">
        {{ description }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: "default-lg",
    },
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
    },
    href: {
      type: String,
    },
    routeLink: {
      type: Object,
    },
    imageUrl: {
      type: String,
      required: true,
    },
  },
};
</script>
