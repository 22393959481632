<template>
  <div>
    <the-header class="header" />
    <div id="beneath-header" class="container-fluid row content">
      <the-sidebar class="" v-if="!cmeOnly"/>
      <div
        id="content"
        :class="{ 'blur-content': $store.state.mobileSidebarOpen, 'col-lg-9 d-md-12': !cmeOnly, 'col-12': cmeOnly }"
      >
        <router-view />
      </div>
    </div>
  </div>
</template>
<script>
import TheHeader from "./components/TheHeader.vue";
import TheSidebar from "./components/TheSidebar.vue";
import baseConfig from "@/store/baseConfig.js";

require("@/assets/MeetingWebAppStyles.css");
require("@/assets/print.css");

export default {
  name: "App",
  components: { TheHeader, TheSidebar },
  data() {
    return {
      cmeOnly: baseConfig.CmeOnly,
    };
  },
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: "AAD Meeting",
    // all titles will be injected into this template
    titleTemplate: "%s | AAD Meeting Web App",
  },
};
</script>

<style scoped>
#beneath-header {
  margin-top: 80px;
}

#content {
  padding: 10px;
}

@media (max-width: 991.98px) {
  .blur-content {
    filter: blur(4px);
    -webkit-transition: 0.5s -webkit-filter cubic-bezier(0, 0, 0.2, 1);
    -moz-transition: 0.5s -moz-filter cubic-bezier(0, 0, 0.2, 1);
    -moz-transition: 0.5s filter cubic-bezier(0, 0, 0.2, 1);
    -ms-transition: 0.5s -ms-filter cubic-bezier(0, 0, 0.2, 1);
    -o-transition: 0.5s -o-filter cubic-bezier(0, 0, 0.2, 1);
    transition: 0.5s filter cubic-bezier(0, 0, 0.2, 1),
      0.5s -webkit-filter cubic-bezier(0, 0, 0.2, 1);
  }
}

@media screen and (max-width: 768px) {
  #beneath-header {
    margin-top: 65px;
  }

  #content {
    overflow-wrap: break-word;
  }
}
</style>
